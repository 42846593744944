import React, {useEffect, useState} from 'react';
import { useForm } from 'react-hook-form';
import {useTranslation} from "react-i18next";
import { useLangContext } from '../../i18n/ProvideLang';
import AppServices from '../../services/general/appServices';
import ModalComponent from "./ModalComponent";
import useFetchItems from '../../hooks/useFetchItems';

const ConfirmActionModal = ({closeModal, ...props}) => {
    const [loading, setLoading] = useState(true);
    const [points, setPoints] = useState();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const [visible, setVisible] = useState(false);
    const {localizationsItems} = useLangContext();

    const {
        items,
        loading: pointLoading
    } = useFetchItems(AppServices.settings)

    useEffect(() => {
        if(items){
            let values = items.find(it => it.type === "form-modal-inputs")?.value;
            setPoints(values);
            if(!values){
                setPoints([localizationsItems?.name, localizationsItems?.phone, localizationsItems?.your_email])
            }
        }
    }, [items])

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    const CustomSubmit = async (data) => {
        let query = new URLSearchParams(window.location.search);

        let newData = {
            fields: {
                ...data
            }
        }


        if(query.toString()){
            query.get('utm_source') && (newData.utm_source = query.get('utm_source'));
            query.get('utm_medium') && (newData.utm_medium = query.get('utm_medium'))
            query.get('utm_campaign') && (newData.utm_campaign = query.get('utm_campaign'))
            query.get('utm_content') && (newData.utm_content = query.get('utm_content'))
            query.get('utm_term') && (newData.utm_term = query.get('utm_term'))
        }else{
            getCookie('utm_source') && newData.append('utm_source', getCookie('utm_source'));
            getCookie('utm_medium') && newData.append('utm_medium', getCookie('utm_medium'));
            getCookie('utm_campaign') && newData.append('utm_campaign', getCookie('utm_campaign'));
            getCookie('utm_content') && newData.append('utm_content', getCookie('utm_content'));
            getCookie('utm_term') && newData.append('utm_term', getCookie('utm_term'));
        }

        setLoading(true);

        const res = await AppServices.requestTemplate(newData);
    
        if (res.statusCode === 200) {
            setVisible(true)
            setTimeout(() => {
                setVisible(false)
                closeModal();
            }, 3000);
        }

        setLoading(false);
    };

    return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false} isWhite={true}>
            <form className='form-modal' onSubmit={handleSubmit(CustomSubmit)}>
                <h4 className="headline-4 mg-md-b-30"></h4>
                <div className="body-2">
                    <div className='modal-title'>
                        <h4>{localizationsItems?.form_title}</h4>
                    </div>
                    <p>{localizationsItems?.form_description}</p>
                    {points &&
                        <>
                            {points.map((point, index) =>
                                <div className="form-group mt-3" key={index}>
                                    <input type="text" {...register(point)} className="form-control" placeholder={point}/>
                                </div>
                            )}
                        </>
                    }
                </div>
                <div className={`row row--multiline justify-content-end`}>
                    <div>
                        <button className='default-button mt-30' type='submit'>{localizationsItems?.form_button}</button>
                    </div>
                </div>
                <div className={visible ? "success-request success-request-visible" : 'success-request'}>
                    <div className='modal-title'>
                        <h4>{localizationsItems?.form_success}</h4>
                    </div>
                    <p>{localizationsItems?.form_success_message}</p>
                </div>
            </form>
        </ModalComponent>
    );
};

export default ConfirmActionModal;