import React from 'react'
import ModalComponent from './ModalComponent'

const WorkerModal = ({closeModal, videoUrl, card, ...props}) => {
  return (
    <ModalComponent
    {...props} closeModal={closeModal} ariaHideApp={false} isWhite={true}>
        <div className='worker-modal'>
            <div className="row">
                <div className="col-sm-12 col-lg-4">
                    <img src={card.image} alt="image"/>
                </div>
                <div className="col-sm-12 col-lg-8">
                    <h4>{card?.name}</h4>
                    <p className='position'>{card.position}</p>
                    <div dangerouslySetInnerHTML={{__html: card.description}}></div>
                </div>
            </div>
        </div>
    </ModalComponent>
  )
}

export default WorkerModal